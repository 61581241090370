<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="20" size="35" ref="toppane">
					<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
						:options.sync="options" :server-items-length="totalItems"
    					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
						:loading="loading" loading-text="加载中..." show-select single-select>
						<template v-slot:top>
							<div class="d-flex flex-wrap" ref="tabletopbar">
								<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
									<v-btn>待诊断</v-btn>
									<v-btn>已诊断</v-btn>
									<v-btn>今日待传</v-btn>
									<v-btn>全部待传</v-btn>
								</v-btn-toggle>
								<v-text-field placeholder="姓名" v-model.trim="pname" dense clearable hide-details style="flex: 0 1 auto" class="ml-4"
									append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
							</div>
							<v-divider/>
						</template>
						<template v-slot:item.age="{ item }">
							{{getAge(item)}}
						</template>
						<template v-slot:item.progress.uploadECG="{ item }">
							{{formatTime(item.progress.uploadECG)}}
						</template>
						<template v-slot:item.progress.diagnoseECG="{ item }">
							{{formatTime(item.progress.diagnoseECG)}}
						</template>
						<template v-slot:item.opts="{ item }">
							{{getOptsStr(item)}}
						</template>
					</v-data-table>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="max-height:100%" class="overflow-y-auto pa-4">
					<v-btn v-if="canUpload" large color="primary" @click.stop="localUpload" :disabled="!canUpload" outlined class="ml-2">上传心电图</v-btn>
					<v-btn large color="primary" outlined class="ml-2" v-print="'#printecq'" :disabled="!diagnosedPic">打印</v-btn>
					<v-container fluid v-if="selected.length > 0 && !!selected[0].ECGs" class="mt-1">
						<v-divider/>
						<v-row class="mt-2">
							<v-card v-for="(r,idx) in selected[0].ECGs" :key="idx" class="ma-2" max-width="320">
								<v-img :src="r" lazy-src="../assets/logo.png" contain width="320" @click.stop="showImage(idx)">
									<template v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</v-card>
							<v-card width="400" class="ma-2 pa-4">
								<v-form ref="diagform" :readonly="!canDiag">
									<v-textarea outlined label="诊断" height="300" :rules="acRules" v-model.trim="diagtxt"/>
								</v-form>
								<v-card-actions>
									<v-spacer/>
									<v-btn color="primary" :loading="loading" :disabled="loading || !canDiag" @click.stop="save">保存诊断</v-btn>
								</v-card-actions>
							</v-card>
						</v-row>
					</v-container>
	            </div>
			</pane>
		</splitpanes>
		<image-uploader v-model="iu" :clouddir="clouddir" @saved="onPicSaved"/>
		<viewer v-if="selected.length > 0 && !!selected[0].ECGs" @inited="inited" ref="viewer" :images="selected[0].ECGs" style="display:none">
			<template slot-scope="scope">
				<img v-for="src in scope.images" :src="src" :key="src">
			</template>
		</viewer>
		<div style="display:none">
			<img :src="diagnosedPic" id="printecq" width="100%"></img>
		</div>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import {formatTime, formatDate, calculateAge, today} from '../utils'
	import { Splitpanes, Pane } from 'splitpanes'
	import 'splitpanes/dist/splitpanes.css'
	import ImageUploader from '@/components/ImageUploader.vue'
	import print from 'vue-print-nb'

    export default {
		directives: {
			print   
		},
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:80},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'证件号', value:'pii.idcard', width:120},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'上传时间', value:'progress.uploadECG', width:180},
                    {text:'诊断时间', value:'progress.diagnoseECG', width:180},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				options: {},
				totalItems: 0,
				filter: 0,
				pname: '',
				tableheight: undefined,
				canUpload: false,
				canDiag: false,
				iu: false,
				acRules: [v => !!v || '此项为必填'],
				diagtxt: '',
            }
        },
		computed: {
			diagnosedPic() {
				if (this.selected.length > 0 && !!this.selected[0].eeresults) {
					const src = this.selected[0].eeresults.find(x => x.substring(x.length - 7) === 'ECG.jpg');
					return src;
				}
				return null;
			},
			clouddir() {
				if (this.selected.length === 0) return "";
				return `ECGs/${this.selected[0]._id}`;
			}
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.diagtxt = newitem[0].ECGdiagnosis;
			}
		},
		created() {
			this.formatTime = formatTime;
		},
        mounted() {
			this.canUpload = this.$hasPrivilege('术前检查单');
			this.canDiag = this.$hasPrivilege('心电图');
			this.authed = this.$hasPrivilege(['术前检查单', '心电图']);
			if (!this.authed) return;
			this.fetchData();
		},
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
				setTimeout(() => {
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO + (item.opts.TX ? '/' + item.opts.TX : '');
			},
			inited(viewer) {
				this.$viewer = viewer;
			},
			showImage(idx) {
				if (this.selected.length === 0) return;
				if (this.selected[0].pathologyReports === 0) return;
				this.$viewer.show();
				this.$viewer.view(idx);
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const filters = [
					{ECGs:_.exists(true), 'progress.diagnoseECG':_.exists(false)},
					{'progress.diagnoseECG':_.exists(true)},
					{ECGs:_.exists(false), apntBldDate:_.gt(today.begin()).lt(today.end())},
					{ECGs:_.exists(false), apntBldDate:_.exists(true)},
				];
				let filter = [{"payment.actual":_.exists(true)}, {"endoscopyResult":_.exists(false)}];
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			localUpload() {
				this.imagefiles = [];
				this.iu = true;
			},
			async onPicSaved(files) {
				if (this.selected.length === 0 || files.length === 0) return;
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'uploadECG',
							data: {
								id,
								files
							}
						}
					});
					this.selected[0].ECGs = files;
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('上传失败');
				}
				this.loading = false;
			},
			save() {
				this.$refs.diagform.validate();
				if (!this.$refs.diagform.validate()) return;
				const img = new Image();
				img.crossOrigin="anonymous";
				img.onload = () => {
					const canvas2 = document.createElement("canvas");
					canvas2.width = img.width;
					canvas2.height = img.height;
					const ctx = canvas2.getContext('2d');
					ctx.drawImage(img, 0, 0);
					ctx.font = "36px sans";
					const x = img.width * 0.39;
					const y = img.height * 0.82;
					const strs = this.diagtxt.split('\n');
					for (let i = 0; i < strs.length; ++i) {
						ctx.fillText(strs[i], x, y + 60 * i);
					}
					const u = this.$getCurrentUser();
					ctx.fillText(`诊断医生：${u.name}`, img.width * 0.76, img.height * 0.9);
					canvas2.toBlob(async(blob)=>{
						this.loading = true;
						try {
							const id = this.selected[0]._id;
							const cloudfilename = `eeresults/${id}/ECG.jpg`;
							const res = await this.$tcbapp.uploadFile({
								config: {env: 'huasan-6gaadaha7ceb62a9'},
								cloudPath: cloudfilename,
								filePath: blob
							});
							await this.$tcbapp.callFunction({name:"wp2mp",
								data:{
									funcname:'diagnoseECG',
									data: {
										id,
										diagnosis: this.diagtxt,
										file: res.download_url
									}
								}
							});
							this.$dialog.message.success('保存成功');
						} catch(err) {
							console.error(err);
							this.$dialog.message.error('保存失败');
						}
						this.loading = false;
					}, 'image/jpeg', 0.5);
				}
				const len = this.selected[0].ECGs.length;
				img.src = this.selected[0].ECGs[len-1]; // when cors error, try add '?not-from-cache-please' to the end of url
			}
		},
        components: { Splitpanes, Pane, ImageUploader }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
</style>